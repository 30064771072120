.detailModal {
  :global {
    .ant-form-item {
      margin-bottom: 12px !important;

      .ant-space-compact {
        .ant-form-item {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}
